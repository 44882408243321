.app {
  display: grid;
  grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr 0.2fr;
  grid-template-rows: 50px 2.5fr 0.1fr;
  grid-auto-columns: 1fr;
  gap: 2px 2px;
  grid-auto-flow: column;
  grid-template-areas:
    "leftnav topnav topnav topnav topnav topnav rightnav"
    "leftnav main main main main main rightnav"
    "leftnav . footer footer footer . rightnav";
}

.leftnav {
  grid-area: leftnav;
}

.rightnav {
  grid-area: rightnav;
}

.footer {
  grid-area: footer;
}

.topnav {
  grid-area: topnav;
  font-size: "large";
}

.main {
  grid-area: main;
}

.tab {
  font-size: large;
  margin: 0 10px;
}

.tab:hover {
  color: blue;
}

h1 {
  font-size: 2em;
  color: #000;
}

h2 {
  font-size: 1.7em;
  color: #000;
}

h3 {
  font-size: 1.3em;
  color: #333;
}

h4 {
  font-size: 1em;
  color: #333;
}

h5 {
  font-size: 0.83em;
  color: #333;
}

h6 {
  font-size: 0.67em;
  color: #333;
}

/* disable scrolling */
.no-scroll {
  overflow: hidden;
}